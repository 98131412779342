@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.farmacia-container {
  background-color: #f8f9fa;
  overflow: hidden;
}

.farmacia-header {
  background-color: #e9ecef;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #dee2e6;
}

.farmacia-employees {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.vertical-line {
  border-left: 1px solid #dee2e6;
  height: 100%;
}

.employee-item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.employee-item p {
  margin-left: 10px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  overflow-x: auto;
}

.swal2-popup .table {
  width: 100%;
  margin-top: 20px;
}

.swal2-popup .table th,
.swal2-popup .table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3rem;
  line-height: 1.5rem;
  white-space: normal;
}

@keyframes slideIn {
  from {
    width: 0;
    opacity: 0;
  }
  to {
    width: 40vw;
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    width: 40vw;
    opacity: 1;
  }
  to {
    width: 0;
    opacity: 0;
  }
}

.animate-slide-in {
  animation: slideIn 1s forwards;
}

.animate-slide-out {
  animation: slideOut 1s forwards;
}

.content-invisible {
  opacity: 0;
}

.carousel {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel-image {
  width: 100%;
  height: 100%;
  display: none;
}

.carousel-image.active {
  display: block;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@layer components {
  .table-body > td {
    @apply py-2 px-1 text-slate-600;
  }

  .table-head > th {
    @apply py-2 px-1 text-slate-600;
  }

  .custom-select {
    @apply block w-full h-12 bg-white rounded-xl pl-4 border border-gray-300;
  }

  .main-input {
    @apply block w-full h-12 bg-white rounded-xl pl-4 border border-gray-300;
  }

  .main-input:read-only {
    @apply bg-gray-200 border-gray-200;
  }

  .main-dropdown {
    @apply block w-full h-12 bg-[#F3F3F3] rounded shadow-md pl-4 appearance-none;
  }

  .cefa-button {
    @apply w-full flex items-center rounded px-4 py-2 bg-[#205f43] text-base text-white hover:bg-[#6CB7A2] active:bg-[#6CB7A2];
  }

  .cefa-button.active {
    @apply bg-[#6CB7A2];
  }

  .reject-button {
    @apply w-full rounded border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500;
  }

  .menu-item {
    @apply flex bg-[#205f43] px-4 py-2 rounded text-white gap-2 whitespace-nowrap hover:bg-[#6CB7A2];
  }

  .menu-item.active {
    @apply bg-[#6CB7A2];
  }

  /* .menu-item {
    @apply flex bg-[#0F9cd8] px-4 py-2 rounded text-white gap-2 whitespace-nowrap hover:bg-[#0BB8D1];
  }

  .menu-item.active {
    @apply bg-[#065E74];
  } */

  .bg-drawer {
    @apply bg-black flex flex-row absolute top-0 left-0 w-full h-full bg-opacity-50 backdrop-blur-sm z-30;
  }
}
